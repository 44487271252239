<!--
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2024-02-19 10:34:48
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-18 10:31:10
-->
<template>
  <el-dialog
    :visible.sync="previewFileShow"
    :before-close="cancel"
    :close-on-click-modal="false"
    title="预览"
    width="800px"
  >
    <img v-if="currentFile.fileType == 'image'" class="preview-img" :src="currentFile.sourceFileUrl" alt="" />
    <video
    v-else
     ref="videoRef"
      width="760px"
      height="560px"
      :src="currentFile.sourceFileUrl"
      controls>
          </video>
    <div slot="footer">
      <el-button type="primary" @click="submitHandle()">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    previewFileShow: {
      type: Boolean,
      default: false
    },
    currentFile: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {}
  },
  methods: {
    cancel () {
      this.$parent.previewFileShow = false
    },
    submitHandle () {
      this.$parent.previewFileShow = false
    }
  }
}
</script>
<style scoped lang="scss">
.preview-img {
  width: 750px;
  // height: 440px;
}
</style>
