<!--
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2023-11-20 17:40:05
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-14 13:51:24
-->
<template>
    <el-dialog
      :visible.sync="editCourseInfoShow"
      :before-close="cancel"
      :close-on-click-modal="false"
      title="编辑"
      width="600px"
    >
      <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        label-width="100px"
        @keyup.enter.native="submitHandle()"
      >
        <el-form-item label="课程名称" prop="fileName">
          <el-input v-model="dataForm.fileName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="应学时长" prop="learnDuration" class="time" v-if="currentInfo.fileType !='image'&&currentInfo.fileType != 'file'">
          <el-input placeholder="请输入内容" :maxlength="2" v-model="hour" :disabled="!hour" style="width:150px;margin-right:5px;">
            <template slot="append">时</template>
          </el-input>
          <el-input placeholder="请输入内容"  :maxlength="2" v-model="minute" style="width:150px;margin-right:5px;">
            <template slot="append">分</template>
          </el-input>
          <el-input placeholder="请输入内容"  :maxlength="2" v-model="second" style="width:150px;">
            <template slot="append">秒</template>
          </el-input>
          <!-- <el-input v-model="dataForm.learnDuration" style="width:380px" placeholder="请输入">
          </el-input>
          <el-select v-model="dataForm.timeType" style="width:80px" placeholder="">
          <el-option
            v-for="item in timeTypeList"
            :key="item.value"
            :label="item.name"
            :value="item.value">
          </el-option>
        </el-select> -->
        </el-form-item>
        <el-form-item label="带教老师" class="time">
          <el-select
          v-model="dataForm.lecturerTeamId"
          @change="changelecturerTeam"
          placeholder="请选择"
          style="width:230px;"
          >
            <el-option
              v-for="item in teacherTeamList"
              :key="item.id"
              :label="item.lecturerTeam"
              :value="item.id"
              >
            </el-option>
          </el-select>
          <el-select
          v-model="dataForm.teacherUserIds"
          placeholder="请选择"
          style="width:230px;"
          >
            <el-option
              v-for="item in teachUserList"
              :key="item.employeeId"
              :label="item.employeeName"
              :value="item.employeeId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="submitHandle()">确认</el-button>
      </div>
    </el-dialog>
  </template>
<script>
import {
  getTeachUserListApi,
  getTeachTeamListApi
} from '@/api/settings'
import { courseCatalogueEditApi } from '@/api/course'
import fileType from '@/mixins/fileType'
export default {
  mixins: [fileType],
  props: {
    editCourseInfoShow: {
      type: Boolean,
      default: false
    },
    currentInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dataForm: {
        fileName: '',
        learnDuration: '',
        sourceFileId: '', // 关联资料库
        lecturerTeamId: '', // 讲师团队id
        teacherUserIds: '', // 带教老师ID
        teacherUserNames: '' // 带教老师名称

      },
      hour: '',
      minute: '',
      second: '',
      dataRule: {
        learnDuration: [
          {
            required: true,
            message: '请输入学习时长',
            trigger: 'change'
          }
        ],
        fileName: [
          {
            required: true,
            message: '请输入课程名称',
            trigger: 'change'
          }
        ]
      },
      teacherTeamList: [], // 讲师团队
      teachUserList: [], // 讲师列表
      timeTypeList: [
        {
          name: '分钟',
          value: '1'
        },
        {
          name: '秒',
          value: '2'
        }
      ]
    }
  },
  created () {
    this.getTeachTeamList()
    this.getTeachUserList(this.currentInfo.lecturerTeamId)

    this.dataForm = JSON.parse(JSON.stringify(this.currentInfo))
    this.getTime(this.dataForm.learnDuration)
  },
  methods: {
    getTime (value) {
      let second = parseInt(value)
      //  分
      let minute = 0
      //  小时
      let hour = 0
      //  天
      //  let day = 0
      //  如果秒数大于60，将秒数转换成整数
      if (second > 60) {
        //  获取分钟，除以60取整数，得到整数分钟
        minute = parseInt(second / 60)
        //  获取秒数，秒数取佘，得到整数秒数
        second = parseInt(second % 60)
        //  如果分钟大于60，将分钟转换成小时
        if (minute > 60) {
          //  获取小时，获取分钟除以60，得到整数小时
          hour = parseInt(minute / 60)
          //  获取小时后取佘的分，获取分钟除以60取佘的分
          minute = parseInt(minute % 60)
        }
      }
      this.hour = hour ? parseInt(hour) : 0
      this.minute = minute ? parseInt(minute) : 0
      this.second = second ? parseInt(second) : 0
    },
    getTeachTeamList () {
      getTeachTeamListApi().then(res => {
        if (res.code === 200) {
          this.teacherTeamList = res.data
        }
      })
    },
    getTeachUserList (lecturerTeamId) {
      getTeachUserListApi({
        lecturerTeamId
      }).then(res => {
        if (res.code === 200) {
          this.teachUserList = res.object.results
        }
      })
    },
    changelecturerTeam (value) {
      this.dataForm.teacherUserIds = ''
      this.getTeachUserList(value)
    },
    cancel () {
      // 取消
      this.$parent.editCourseInfoShow = false
    },
    submitHandle () {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.teachUserList.forEach(item => {
            if (this.dataForm.teacherUserIds === item.employeeId) {
              this.dataForm.teacherUserNames = item.employeeName
            }
          })
          if (this.currentInfo.fileType != 'image' && this.currentInfo.fileType != 'file') {
            if ((this.minute == '' || this.minute == 0) && (this.second == '' || this.second == 0)) {
              this.$message.error('应学时长不能为空！')
              return
            }
            this.dataForm.learnDuration = parseInt(this.hour * 3600) + parseInt(this.minute * 60) + parseInt(this.second)
            if (this.dataForm.learnDuration > this.dataForm.fileDuration) {
              this.$message.error('应学时长不能大于课程时长！')
              return
            }
          }
          courseCatalogueEditApi({
            ...this.currentInfo,
            ...this.dataForm,
            courseId: this.$route.query.id
          }).then(res => {
            if (res.code === 200) {
              this.$parent.editCourseInfoShow = false
              this.$emit('uploadCourseCatalogue')
              this.$message.success('操作成功！')
            }
          })
        }
      })
    }
  }
}
</script>
  <style lang="scss" scoped>
/deep/.el-input-group__append{
    background: #FFFFFF;
    color: #1A1A1A;
    border-left:none ;
}
.time{
/deep/.el-input__inner{
    // border-right: none;
}
}
</style>
