
<template>
  <div>
    <div class="course-box">
      <div class="course-box-title">
        <com-title>课程安排</com-title>
        <div class="right-btn">
          <el-button class="add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}" @click="choosedDataBank"
            >从资料库中选择</el-button
          >
          <el-button type="primary" @click="uploadCourseFile"
            >上传课件</el-button
          >
        </div>
      </div>
      <div class="content">
        <div class="stage-box-list">
          <div class="empty-box" v-if="courseCatalogueList.length === 0">
            <PlaceholderMap text="暂无课程目录噢~" />
          </div>
          <div v-else>
            <div class="stage-box-list-info" v-for="(item,index) in courseCatalogueList" :key="index">
              <div class="stage-box-left" >
                <div class="num">
                  <i></i>
                  {{Number((index+1))>=10 ? (index+1) :'0'+(index+1)}}
                </div>
                <div class="stage-box-list-info-l">
                  <div class="stage-box-list-info-l-n ">
                    <div class="hover-name">{{item.fileName}}</div> <span v-if="item.fileTypeValue">{{item.fileTypeValue}}</span>
                  </div>
                  <div class="stage-box-list-info-l-t">
                    <span v-if="item.fileType === 'video'">课程时长 {{item.fileDuration?formatSeconds(item.fileDuration):'—'}}</span>
                    <span v-if="item.fileType === 'video'" class="line-box-two"></span>
                    <span v-if="item.fileType === 'video'">应学时长{{item.learnDuration?formatSeconds(item.learnDuration):'—'}}</span>
                    <span v-if="item.fileType === 'video'" class="line-box-two"></span>
                    <span>带教老师 {{item.teacherUserNames||'—'}}</span>
                  </div>
                </div>
              </div>
              <div class="stage-box-list-info-r">
                <el-button type="text" @click="previewCourseInfo(item)">预览</el-button>
                <el-button type="text" @click="editCourseInfo(item)">编辑</el-button>
                <el-button type="text" @click="deleteCourseInfo(item,index)">删除</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
       <!-- 编辑课程名称 -->
       <EditCourseInfo
      v-if="editCourseInfoShow"
      :editCourseInfoShow="editCourseInfoShow"
      :currentInfo="currentInfo"
      @uploadCourseCatalogue="$emit('uploadCourseCatalogue')"
    />
        <!-- 列表预览 -->
        <PreviewFile
      v-if="previewFileShow"
      :previewFileShow="previewFileShow"
      :currentFile="currentFile"
      ref="previewFile"
    ></PreviewFile>
  </div>
</template>

<script>
import comTitle from '@/components/comTitle/index.vue'
import PlaceholderMap from '@/components/placeholderMap/index.vue'
import EditCourseInfo from './editCourseInfo.vue'
import { courseCatalogueDeteleApi } from '@/api/course'
import fileType from '@/mixins/fileType'
import PreviewFile from '@/components/previewFile/index.vue'
export default {
  mixins: [fileType],
  components: {
    comTitle,
    PlaceholderMap,
    EditCourseInfo,
    PreviewFile
  },
  props: {
    courseCatalogueList: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {
      stageList: [11],
      editCourseInfoShow: false,
      currentInfo: {},
      previewFileShow: false,
      currentFile: {}
    }
  },
  methods: {
    choosedDataBank () {
      // 从资料库中选择
      this.$emit('choosedDataBank', '1')
    },
    uploadCourseFile () {
      // 上传课件
      this.$emit('uploadCourseFile', '1')
    },
    editCourseInfo (item) {
      // 编辑课程
      this.editCourseInfoShow = true
      this.currentInfo = item
    },
    previewCourseInfo (row) {
      console.log(row, '=====')
      if (row.fileType === 'image' || row.fileType === 'video') {
        this.previewFileShow = true
        this.currentFile = row
      } else {
        const previewUrl = this.getPreviewUrl(row)
        window.open(previewUrl)
      }
    },
    deleteCourseInfo (item) {
      // 删除
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        courseCatalogueDeteleApi([item.id]).then(res => {
          if (res.code === 200) {
            this.$emit('uploadCourseCatalogue')
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0;
  height:  calc(100vh - 460px);
  min-height: 0px;
  // overflow: auto;
  margin-top: 16px;
  padding-bottom: 20px;
}
.course-box-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-group-btn {
  width: 130px;
  // border: 1px solid #0089ff;
  font-size: 14px;
  border-color:var(--lock-btn)!important;
  color:var(--lock-btn)!important;
}
.stage-box-list {
  .empty-box {
    margin-top: 16px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .stage-box-list-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    margin-bottom: 16px;
    background: linear-gradient(to bottom, #f0f8ff, #ffffff);
    border: 1px solid #e7f4ff;
    border-radius: 8px;
    .stage-box-list-info-l {
      .stage-box-list-info-l-n {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        span {
          display: inline-block;
          margin-left: 16px;
          padding: 2px 12px;
          background-color: #0089ff;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          border-radius: 8px 8px 8px 0;
        }
      }
      .stage-box-list-info-l-t {
        display: flex;
        align-items: cenetr;
        font-size: 12px;
        color: #666666;
        line-height: 16px;
        .line-box-two {
          display: inline-block;
          margin: 0 12px;
          width: 1px;
          height: 16px;
          background-color: #dcdcdc;
        }
      }
    }
  }
}
.stage-box-left {
  display: flex;
  align-items: center;
}
.num {
  font-size: 30px;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 700;
  color: #0089ff;
  position: relative;
  z-index: 2;
  margin-right: 24px;
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 56px;
    height: 10px;
    background: #d8edff;
    border-radius: 2px 2px 2px 2px;
    z-index: -1;
  }
}

</style>
